<template>
  <div class="row" style="margin-top: -70px">
    <div class="col-10 p-0">
      <p class="h2 text-secondary m-3">
        <i
          style="font-size: 22px; color: #072648 !important"
          :class="$route.meta.iconClass"
        ></i>
        <span style="font-size: 18px" class="font-weight-light">
          Equipe :
          <span style="font-size: 22px" class="font-weight-bold">{{ items.name }}</span>
        </span>
        <span style="font-size: 22px"></span>
      </p>
    </div>
    <div class="col-12">
      <div class="col-12 p-0" style="margin-top: -20px">
        <h4 class="card-title mt-4 mb-1">Liste des Collaborateurs :</h4>
        <hr />
      </div>
      <b-table
        :items="items.user"
        :fields="members.tableFields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        bordered
        striped
      >
        <template v-slot:cell(avatarUrl)="data">
          <div class="d-flex justify-content-center">
            <b-avatar
              v-b-tooltip.hover="data.item.firstname + ' ' + data.item.lastname"
              variant="info"
              size="2rem"
              :src="'https://dev.api.callstrategy.fr/' + data.item.avatarUrl"
              class="mr-1"
            ></b-avatar>
          </div>
        </template>
        <template v-slot:cell(firstname)="data">
          <div>
            {{ data.value }}
          </div>
        </template>
        <template v-slot:cell(lastname)="data">
          <div>
            {{ data.value }}
          </div>
        </template>
        <template v-slot:cell(isActif)="data">
          <div v-if="data.value == true">
            <span class="badge badge-success">Actif</span>
          </div>
          <div v-else>
            <span class="badge badge-danger">Inactif</span>
          </div>
        </template>

        <template v-slot:cell(actions)="data"> </template>
      </b-table>
      <br />
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="table-list"
        align="right"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    sortBy: "name",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    members: {
      tableItems: [],
      tableFields: [
        { key: "avatarUrl", label: "Image", thStyle: { textAlign: "center !important" } },
        { key: "firstname", label: "Prénom", sortable: true },
        { key: "lastname", label: "Nom", sortable: true },
        { key: "isActif", label: "Compte", sortable: true },
      ],
    },
  }),

  created() {
    this.$store.dispatch("users/fetchAllResponsables").then(() => {
      this.loadingData = false;
    });
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    responsables() {
      return this.fetchAllResponsables;
    },
    rows() {
      return this.items.user ? this.items.user.length : 0;
    },
  },
  watch: {
    RESPONSABLES: function () {
      this.tableItems = this.RESPONSABLES.map((item) => ({
        ...item,
        actions: this.actions,
      }));
    },
  },
};
</script>

<style>
.sub-modal[data-v-23747a02] {
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  min-width: 80%;
  max-width: 82%;
  min-height: 95%;
  max-height: 95%;
  position: fixed !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow-y: auto;
  overflow-x: hidden !important;
  height: auto;
}
</style>
