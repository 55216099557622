<template>
  <div>
    <!-- {{ items.map((item) => item.manager) }} -->
    <b-table
      responsive
      :items="tabledata"
      :fields="legalEntity.tableFields"
      :busy="loadingdata"
      show-empty
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      bordered
      striped
    >
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <template v-slot:cell(name)="data">
        <b-link
          v-if="!data.item.hasOwnProperty('editMode')"
          @click="
            $router.push({
              name: 'show-team',

              params: {
                slug: data.item.slug ? data.item.slug : '8787',
                id: data.item.id,
              },
            })
          "
          >{{ data.value }}</b-link
        >
        <div v-else>
          <b-form-input
            v-model="libelle"
            placeholder="Equipe"
            type="text"
            :class="{
              'is-invalid': $v.libelle.$error && $v.libelle.$dirty,
            }"
          ></b-form-input>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.libelle.$dirty"
          >
            {{ !$v.libelle.required ? "Champ obligatoire" : "" }}
          </span>
        </div>
      </template>
      <template v-slot:cell(manager)="data">
        <TableResponsable
          v-if="!data.item.hasOwnProperty('editMode')"
          :responsable="data.value"
        />
        <div v-else class="p-0 row col-12 mx-auto">
          <v-select
            append-to-body
            label="text"
            :reduce="(user) => user.value"
            placeholder="Responsable"
            v-model="responsable"
            :options="responsables"
            class="col-12 p-0"
            style="padding-right: 0 !important"
            :class="{
              'is-invalid form-control p-0':
                $v.responsable.$error && $v.responsable.$dirty,
            }"
          ></v-select>
          <br />
          <span
            style="font-size: x-small"
            class="text-danger pt-1 font-weight-normal"
            v-if="$v.responsable.$dirty"
          >
            {{ !$v.responsable.required ? "Champ obligatoire" : "" }}
          </span>
        </div>
      </template>
      <template v-slot:cell(members)="data">
        <div v-if="!data.item.hasOwnProperty('editMode')" class="text-center">
          <b-badge
            pill
            @click="datacoll(data.item)"
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            >{{ data.value ? data.value.length : 0 }}</b-badge
          >
        </div>
        <div v-else class="text-center">
          <b-badge
            pill
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            >{{ 0 }}</b-badge
          >
        </div>
      </template>
      <template v-slot:cell(organisation)="data">
        <div v-if="!data.item.hasOwnProperty('editMode')">
          <span v-if="name">
            {{ name ? name : "-" }}
          </span>
          <b-link
            v-if="data.value"
            :to="{
              name: 'show-organisation',
              params: {
                slug: data.value.slug ? data.value.slug : '61f1159652a44',
                id: data.value.id,
              },
            }"
            >{{ data.value ? data.value.libelle : "-" }}</b-link
          >
        </div>

        <b-form-select
          disabled
          v-else
          v-model="name"
          :options="[name]"
        ></b-form-select>
      </template>
      <template v-slot:cell(actions)="data">
        <table-actions
          :actions="isAdmin || isSuper ? ['delete'] : []"
          :editMode="data.item.hasOwnProperty('editMode')"
          @cancelItem="cancelItem"
          @confirmItem="addteam"
          @deleteItem="$emit('deleteItem', data.item.id)"
        />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
    <div v-if="showActeurs" to="modals-xyz-548">
      <Modal v-if="name" @close="close">
        <CollaborateurTeamList
          :items="selectedColl"
          :name="name"
          @close="close"
        />
      </Modal>
      <Modal v-else @close="showActeurs = !showActeurs">
        <CollaborateurList :items="selectedColl" />
      </Modal>
    </div>
  </div>
</template>

<script>
import SortedTablePlugin from "vue-sorted-table";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import CollaborateurList from "./CollaborateurList";
import { mapGetters } from "vuex";
import Modal from "@/components/custom/Modal.vue";
import TableActions from "../../../../components/custom/TableActions";

import Vue from "vue";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import CollaborateurTeamList from "./CollaborateurTeamList";
import { USER_URL } from "@/helpers/constURL";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});
export default {
  components: {
    CollaborateurTeamList,
    TableResponsable,
    CollaborateurList,
    TableActions,
    Modal,
  },
  data: () => ({
    sortBy: "name",
    responsable: "",
    libelle: "",
    loadingdata: true,
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    selectedColl: [],
    showActeurs: false,
    tabledata: null,
    actions: ["delete"],
    legalEntity: {
      tableFields: [
        { key: "name", label: "Equipe", sortable: true },
        {
          key: "manager",
          label: "Responsable",
          sortable: true,
        },
        {
          key: "organisation",
          label: "Organisation de rattachement",
          sortable: true,
        },
        {
          key: "members",
          label: "Nombre de collaborateurs",
          sortable: true,
        },

        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        },
      ],
      tableItems: [
        {
          team_label: "Lorem 1",
          responsable: {
            name: "S.E",
            full_name: "Steve EWORE",
            img: "https://source.unsplash.com/random/150x150",
          },
          organisation_attachment: "Cadrage",
          collaborators_number: 2,
          actions: ["show", "edit", "delete"],
        },
        {
          team_label: "Lorem 2",
          responsable: {
            name: "S.E",
            full_name: "Steve EWORE",
            img: "https://source.unsplash.com/random/150x150",
          },
          organisation_attachment: "Cadrage",
          collaborators_number: 6,
          actions: ["show", "edit", "delete"],
        },
      ],
    },
  }),
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("teams/fetchAllTeams");
  },
  methods: {
    reload() {
      this.$emit("reload");
    },
    close() {
      this.showActeurs = !this.showActeurs;
      var data = this.selectedColl.user.some(
        (contribute) => contribute.editMode
      );
      if (data) {
        this.selectedColl.user.splice(0, 1);
      }
    },

    addteam() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var item = {
          name: this.libelle,
          manager: this.responsable,
          organisation: this.id,
          description: " ",
          niveau: 1,
        };
        this.$store.dispatch("teams/createTeam", item).then(() => {
          this.$store.dispatch("teams/fetchAllTeams");

          this.$store
            .dispatch("organisation/fetchActeur", this.$route.params.id)
            .then(() => {
              this.$store.dispatch("teams/fetchAllTeams");

              Swal.fire({
                title: "L'équipe est bien créée !",
                type: "success",
                confirmButtonText: "OK",
              });
              this.libelle = null;
              this.responsable = null;
              this.$v.$reset();
            });
        });
      }
    },
    cancelItem: function () {
      if (this.libelle == "" && this.responsable == "") {
        this.$store.dispatch("organisation/fetchActeur", this.$route.params.id);
        this.$v.$reset();
      } else {
        this.libelle = "";
        this.responsable = "";
      }
    },
    datacoll(data) {
      var user = null;
      if (name) {
        user = {
          name: data.name,
          user: data,
          id: data.id,
        };
      } else {
        user = {
          name: data.name,
          user: data.members,
          id: data.id,
        };
      }

      this.selectedColl = user;
      this.showActeurs = !this.showActeurs;
    },
  },
  watch: {
    items() {
      this.tabledata = this.items.map((data) => ({
        ...data,
        organisation_attachment: this.name,
        actions: ["delete"],
      }));
      this.loadingdata = false;
    },
  },
  validations: {
    libelle: {
      required,
    },
    responsable: {
      required,
    },
  },
  computed: {
    rows() {
      return this.items ? this.items.length : 0;
    },
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("teams", ["TEAMS"]),
    ...mapGetters(["isAdmin", "isSuper"]),
    responsables() {
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/${USER_URL}/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      })).filter(
        (respo) =>
          !this.TEAMS.find((item) => item.manager["@id"] === respo.value)
      );
    },
  },
  props: {
    fields: Array,
    items: Array,
    loading: Boolean,
    name: { type: String, default: "" },
    id: String,
  },
};
</script>

<style></style>
