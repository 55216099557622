<template>
  <div class="row" style="margin-top: -70px">
    <div class="col-10 p-0">
      <p class="h2 text-secondary m-3">
        <i
          style="font-size: 22px; color: #072648 !important"
          :class="$route.meta.iconClass"
        ></i>
        <span style="font-size: 18px" class="font-weight-light">
          Organisation :
        </span>
        <span style="font-size: 22px" class="font-weight-bold">
          {{ name }}</span
        >
      </p>
    </div>
    <div class="col-12 col-md-6">
      <div class="d-flex my-2">
        <div>
          <h4 class="font-weight-light">
            Liste des Collaborateurs de l'équipe :
            <span class="mt-4 mb-1 font-weight-bold">{{ items.name }}</span>
          </h4>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 d-flex justify-content-end align-btn-table-2">
      <button
        type="button"
        class="btn h1 mb-2 test-btn btn-secondary d-flex align-items-center"
        @click="addItem"
        style="
          background-color: #007bffff !important;
          border-color: #007bffff !important;
        "
      >
        <i class="mdi mdi-plus mr-2"></i>
        Créer une équipe
      </button>
    </div>
    <div class="col-12">
      <hr class="mt-1 mb-3" />
    </div>
    <div class="col-12">
      <b-table
        :items="items.user"
        :fields="members.tableFields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive="md"
        show-empty
        bordered
        striped
      >
        <template #empty>
          <div class="text-center text-gray">Table vide</div>
        </template>
        <template v-slot:cell(members)="data">
          <TableResponsable
            v-if="!data.item.hasOwnProperty('editMode')"
            :responsable="data.item"
          />
          <div v-else>
            <v-select
              label="text"
              :reduce="(user) => user.value"
              v-model="responsable"
              :options="responsables"
              @input="users"
              style="padding-right: 0 !important"
              :class="{
                'is-invalid form-control p-0':
                  $v.responsable.$error && $v.responsable.$dirty,
              }"
            >
              <template #no-options> Liste vide </template>
            </v-select>
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.responsable.$dirty"
            >
              {{ !$v.responsable.required ? "champ obligatoire" : "" }}
            </span>
          </div>
        </template>
        <template v-slot:cell(function)="data">
          <span v-if="!data.item.hasOwnProperty('editMode')">{{
            data.value ? data.value : "-"
          }}</span>
          <div v-else>
            <b-form-select
              disabled
              v-model="fonction"
              :options="fonctiondata"
            ></b-form-select>
          </div>
        </template>
        <template v-slot:cell(hiearchie)="data">
          <span v-if="!data.item.hasOwnProperty('editMode')">{{
            data.value ? data.value : "-"
          }}</span>
          <div v-else>
            <b-form-select
              disabled
              v-model="fonction"
              :options="hiearchiedata"
            ></b-form-select>
          </div>
        </template>

        <template v-slot:cell(actions)="data">
          <table-actions
            :actions="actions"
            :editMode="data.item.hasOwnProperty('editMode')"
            @cancelItem="cancelItem"
            @confirmItem="addcollaborateur"
            @deleteItem="deleteitem(data.item.id)"
          />
        </template>
      </b-table>
      <br />
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="table-list"
        align="right"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableResponsable from "../../../../components/custom/TableResponsable";
import tableActions from "../../../../components/custom/TableActions";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
export default {
  components: { TableResponsable, tableActions },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    name: String,
  },
  data: () => ({
    sortBy: "name",
    perPage: 4,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    responsable: "",
    fonction: null,
    hiearchie: null,
    sortable: true,
    actions: ["delete"],
    members: {
      tableItems: [],
      tableFields: [
        { key: "members", label: "Collaborateur", sortable: true },
        { key: "function", label: "Fonction", sortable: true },
        { key: "hiearchie", label: "Niveau Hiérachique", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: true,
        },
      ],
    },
  }),
  validations: {
    responsable: {
      required,
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("users/fetchfunction");
    this.$store.dispatch("users/fetchierachie").then(() => {
      this.loadingData = false;
    });
  },
  methods: {
    users() {
      this.$store.dispatch("users/fetchUser", this.responsable).then(() => {
        this.fonction = this.USER.function;
        this.hiearchie = this.USER.hiearchie;
      });
    },
    addcollaborateur() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var members = this.items.user.map((data) => data["@id"]);
        members.splice(0, 1);
        members.push(`api/users/${this.responsable}`);
        var item = {
          id: this.items.id,
          members: members,
        };
        this.$store.dispatch("teams/updateTeam", item).then(() => {
          Swal.fire({
            title: "Le collaborateur est bien créé !",
            type: "success",
            confirmButtonText: "OK",
          });
          this.$store
            .dispatch("organisation/fetchOrganisation", this.$route.params.id)
            .then(() => {
              this.$store.dispatch(
                "organisation/fetchActeur",
                this.$route.params.id
              );
              this.$emit("close");
            });
        });
      }
    },
    deleteitem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result) {
          var data = this.items.user
            .filter((data) => data.id !== id)
            .map((data) => data["@id"]);
          var item = {
            id: this.items.id,
            members: data,
          };
          this.$store.dispatch("teams/updateTeam", item).then(() => {
            Swal.fire("Supprimé!", "", "success");
            this.$store
              .dispatch("organisation/fetchOrganisation", this.$route.params.id)
              .then(() => {
                this.$store.dispatch(
                  "organisation/fetchActeur",
                  this.$route.params.id
                );
                this.$emit("close");
              });
          });
        }
      });
    },
    cancelItem: function () {
      if (this.responsable == "") {
        this.items.user.splice(0, 1);
      } else {
        this.responsable = "";
        this.fonction = "";
        this.hiearchie = "";
      }
    },
    addItem() {
      var data = this.items.user.some((contribute) => contribute.editMode);
      if (data == false) {
        this.items.user.unshift({ editMode: true });
      }
    },
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("users", ["hierachie"]),
    ...mapGetters("users", ["function"]),
    ...mapGetters("users", ["USER"]),

    hiearchiedata() {
      return this.hierachie;
    },
    fonctiondata() {
      return this.function;
    },
    responsables() {
      var users = this.items.user.map((data) => data.id);
      users.splice(0, 1);
      return this.RESPONSABLES.filter(
        (data, index) => data.id !== parseInt(users[index])
      ).map((responsable) => ({
        value: responsable.id,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    rows() {
      return this.items.user ? this.items.user.length : 0;
    },
  },
  watch: {
    RESPONSABLES: function () {
      this.tableItems = this.RESPONSABLES.map((item) => ({
        ...item,
        actions: this.actions,
      }));
    },
  },
};
</script>

<style>
.sub-modal[data-v-23747a02] {
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  min-width: 80%;
  max-width: 82%;
  min-height: 95%;
  max-height: 95%;
  position: fixed !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow-y: auto;
  overflow-x: hidden !important;
  height: auto;
}
.swal2-container {
  z-index: 5454415 !important;
}
</style>
