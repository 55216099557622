var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{attrs:{"responsive":"","items":_vm.tabledata,"fields":_vm.legalEntity.tableFields,"busy":_vm.loadingdata,"show-empty":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"bordered":"","striped":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true},{key:"cell(name)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('b-link',{on:{"click":function($event){return _vm.$router.push({
            name: 'show-team',

            params: {
              slug: data.item.slug ? data.item.slug : '8787',
              id: data.item.id,
            },
          })}}},[_vm._v(_vm._s(data.value))]):_c('div',[_c('b-form-input',{class:{
            'is-invalid': _vm.$v.libelle.$error && _vm.$v.libelle.$dirty,
          },attrs:{"placeholder":"Equipe","type":"text"},model:{value:(_vm.libelle),callback:function ($$v) {_vm.libelle=$$v},expression:"libelle"}}),(_vm.$v.libelle.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.libelle.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1)]}},{key:"cell(manager)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('TableResponsable',{attrs:{"responsable":data.value}}):_c('div',{staticClass:"p-0 row col-12 mx-auto"},[_c('v-select',{staticClass:"col-12 p-0",class:{
            'is-invalid form-control p-0':
              _vm.$v.responsable.$error && _vm.$v.responsable.$dirty,
          },staticStyle:{"padding-right":"0 !important"},attrs:{"append-to-body":"","label":"text","reduce":(user) => user.value,"placeholder":"Responsable","options":_vm.responsables},model:{value:(_vm.responsable),callback:function ($$v) {_vm.responsable=$$v},expression:"responsable"}}),_c('br'),(_vm.$v.responsable.$dirty)?_c('span',{staticClass:"text-danger pt-1 font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.responsable.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1)]}},{key:"cell(members)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"cursor":"pointer","background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"pill":""},on:{"click":function($event){return _vm.datacoll(data.item)}}},[_vm._v(_vm._s(data.value ? data.value.length : 0))])],1):_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"cursor":"pointer","background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"pill":""}},[_vm._v(_vm._s(0))])],1)]}},{key:"cell(organisation)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('div',[(_vm.name)?_c('span',[_vm._v(" "+_vm._s(_vm.name ? _vm.name : "-")+" ")]):_vm._e(),(data.value)?_c('b-link',{attrs:{"to":{
            name: 'show-organisation',
            params: {
              slug: data.value.slug ? data.value.slug : '61f1159652a44',
              id: data.value.id,
            },
          }}},[_vm._v(_vm._s(data.value ? data.value.libelle : "-"))]):_vm._e()],1):_c('b-form-select',{attrs:{"disabled":"","options":[_vm.name]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}},{key:"cell(actions)",fn:function(data){return [_c('table-actions',{attrs:{"actions":_vm.isAdmin || _vm.isSuper ? ['delete'] : [],"editMode":data.item.hasOwnProperty('editMode')},on:{"cancelItem":_vm.cancelItem,"confirmItem":_vm.addteam,"deleteItem":function($event){return _vm.$emit('deleteItem', data.item.id)}}})]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),(_vm.showActeurs)?_c('div',{attrs:{"to":"modals-xyz-548"}},[(_vm.name)?_c('Modal',{on:{"close":_vm.close}},[_c('CollaborateurTeamList',{attrs:{"items":_vm.selectedColl,"name":_vm.name},on:{"close":_vm.close}})],1):_c('Modal',{on:{"close":function($event){_vm.showActeurs = !_vm.showActeurs}}},[_c('CollaborateurList',{attrs:{"items":_vm.selectedColl}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }